import React from "react";
import "./App.css";
import "./index.css";
import WebService from "./Service";
import {MemberRegionMap, EventsMap} from "./Maps";
import Utils from "./Utils";
import { InstagramEmbed } from 'react-social-media-embed';
import { FacebookEmbed } from 'react-social-media-embed';




export default
class Actualities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actualities : [],
            addActualityModal: false,
            winWidth: window.innerWidth
        };
    }
    componentDidMount() {
        WebService.actualities().then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    // sort by date
                    data.sort((a, b) => (a.datetime < b.datetime) ? 1 : -1);
                    this.setState({actualities: data});
                });
            } else {
                // console.log("Erreur lors de la récupération des actualités");
            }
        });

        // On resize, update the state
        window.addEventListener('resize', () => {
            this.setState({winWidth: window.innerWidth});
        });
            
    }

    render() {
        let me =  JSON.parse(localStorage.getItem("currentUser"));
        return (
            <div className=" w-full h-full text-frenchWhite">
                <div className="flex flex-col justify-center items-center">
                    <h2 className=" text-xl">Bienvenue sur le site du <strong className="text-frenchBrown">
                        Corps d'Observation du Rhin</strong></h2>
                    <p>Le COR est une association de reconstitution historique couvrant de la Révolution au Premier Empire (1789-1815), en infanterie de ligne.</p>

                {/* rien de plus simple : contactez-nous ! (cor.reconsitution@gmail.com)" */}
                <p>Pour nous rejoindre, rien de plus simple : contactez nous à sur <a href="mailto:cor.reconstitution@gmail.com" className="text-frenchBrown hover:text-frenchDark transition duration-300">cor.reconstitution@gmail.com</a></p>
                    {/* <p>Pour nous rejoindre, rien de plus simple : <a href="/register" className="text-frenchBrown hover:text-frenchDark transition duration-300"> Inscrivez-vous !</a></p> */}
                </div>
                {/*<hr className="border-frenchBrown border-2 m-auto my-4" />
            <h1 className="text-center text-2xl font-bold">Actualités</h1>*/}

            {/*  Alignés horizontallement & prennet tous l'espace horizontal*/}
            {/* If width of media is >= 871 */}
            {/* {(this.state.winWidth >= 871) &&
                <div id="maps" className="flex flex-row w-full"> 
                  <MemberRegionMap size="w-1/2" />
                    <EventsMap size="w-1/2"/>
                </div> 
             } 
            {(this.state.winWidth < 871) && 
              <div id="maps" className="flex flex-col w-full">
                    <MemberRegionMap size="w-10/12 m-auto"/> 
                    <EventsMap size="w-10/12 m-auto"/> 
                </div> 
            }  */}
            {/* {(this.state.winWidth >= 1100) && */}
            {/* <EventsMap size="w-12/12 m-auto p-10"/>  */}
            {/* } */}
            {/* {(this.state.winWidth < 1100) &&  */}
                {/* <EventsMap size="w-12/12 m-auto p-10"/>  */}

            {/* } */}
            <EventsMap size={(this.state.winWidth <= 1100 ? "w-12/12 m-auto " : "w-7/12  m-auto ") + " m-auto" }/>



            {Utils.isAdmin(me) && 
            <div>
                <button className=" bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded p-2 m-1" onClick={() => this.setState({addActualityModal: true})}>Ajouter une actualité</button>
                {this.state.addActualityModal && 
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    {/* Form with title, content, type and link */}
                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                            </div>
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full bg-frenchBlack/75">
                                <div className="border-frenchBrown border-2">
                                    <div className="text-center">
                                        <h3 className="text-lg  bg-frenchBrown text-frenchWhite font-bold" id="modal-title">
                                            Ajouter une actualité
                                        </h3>
                                        <div className="text-frenchWhite">
                                            <form
                                                className="bg-frenchBlack/50 p-5"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    let title = document.getElementById("title").value;
                                                    let content = document.getElementById("content").value;
                                                    let type = document.getElementById("type").value;
                                                    let link = document.getElementById("link").value;
                                                    let is_public = !document.getElementById("is_public").checked;
                                                    WebService.addActuality(title, content, type, link, is_public).then((response) => {
                                                        if (response.status === 200) {
                                                            let actuality = {
                                                                title: title,
                                                                content: content,
                                                                type: type,
                                                                link: link,
                                                                is_public: !is_public
                                                            };
                                                            response.json().then((data) => {
                                                                let actualities = this.state.actualities;
                                                                actualities.push(actuality);
                                                                this.setState({actualities: actualities});
                                                            });
  

                                                            this.setState({addActualityModal : false});
                                                        } 
                                                    }
                                                    );

                                                }}
                                            >
                                                <div className="bg-frenchBlack/50 p-5">
                                                    {/* Titre de l'actualité */}
                                                    <div className="mb-4">
                                                        <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="title">
                                                            Titre de l'actualité
                                                        </label>
                                                        <input type="text" id="title" placeholder="" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                                    </div>
                                                    {/* Contenu de l'actualité */}
                                                    <div className="mb-4">
                                                        <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="content">
                                                            Contenu de l'actualité
                                                        </label>
                                                        <textarea type="text" id="content" placeholder="" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                                    </div>
                                                    {/* Type de l'actualité */}
                                                    <div className="mb-4">
                                                        <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="type">
                                                            Type de l'actualité
                                                        </label>
                                                        <select id="type" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline">
                                                            <option value="INSTAGRAM">Instagram</option>
                                                            <option value="YOUTUBE">Youtube</option>
                                                            <option value="FACEBOOK">Facebook</option>
                                                            <option value="TEXT">Texte</option>
                                                        </select>
                                                    </div>
                                                    {/* Lien  */}
                                                    <div className="mb-4">
                                                        <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="link">
                                                            Lien d'origine
                                                        </label>
                                                        <input type="text" id="link" placeholder="" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                                    </div>
                                                    {/* Visible uniquement par les members */}
                                                    <div className="mb-4">
                                                        {/* Check box aligned with labal */}
                                                        <table>
                                                            <tr>
                                                                <td>
                                                                    <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="is_public" >
                                                                        Visible pour les membres uniquement
                                                                    </label>
                                                                </td>
                                                                <td>
                                                                    <input type="checkbox" id="is_public" className="border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    {/* Cancel and submit button */}
                                                    <div className="flex justify-center">
                                                        <input type="submit" className=" font-bold text-center text-xl text-frenchWhite p-3 bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded  m-1" value={this.props.validateName}></input>
                                                        <button onClick={ () => {this.setState({addActualityModal : false})}} type="button" className="bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded  m-1">
                                                            <div className="flex flex-col items-center ">
                                                                <p className="font-bold text-center text-xl text-frenchWhite p-3">Annuler</p>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div> 
                                            </form>
                                        </div>
                                    </div>         
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                  
                }
            </div>
            }

            {/* 2 col MAXIMUM */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10">
                {this.state.actualities.map((actuality, index) => {
                    return (
                        <div className="ml-5 mr-5 bg-frenchBlack/50 mb-5 hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded p-2 " key={index}>
                            <hr className="border-frenchBrown border-2 m-auto my-4" />

                            <div className="flex flex-col justify-center items-center">
                                <h2 className="text-xl font-bold pb-5">{actuality.title}</h2>
                                {actuality.type === "YOUTUBE" &&
                                    <iframe 
                                        width="80%"
                                        height="300"
                                        className="border-2 border-frenchBrown rounded-lg"
                                    src={"https://www.youtube.com/embed/"+actuality.content} title={actuality.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                }
                                {actuality.type === "INSTAGRAM" &&
                                <div className="instagram-post">
                                    {/* Paste your Instagram embed code here */}
                                    {/* You can use dangerouslySetInnerHTML to render the HTML provided by Instagram */}
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <InstagramEmbed url={"https://www.instagram.com/p/"+actuality.content} width="500" />
                                    </div>                             
                                    </div>
                                }
                                {actuality.type === "FACEBOOK" &&
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <FacebookEmbed url="https://www.facebook.com/andrewismusic/posts/451971596293956" width={550} />
                                </div>
                                }
                                {actuality.type === "TEXT" &&
                                    <p className="text-justify">{actuality.content}</p>
                                }


                                <p className="text-justify pt-5 text-gray">{"Publié le " + new Date(actuality.datetime).toLocaleDateString("fr-FR")}</p>
                                {actuality.type !== "TEXT" &&
                                    <p className="text-justify text-frenchBrown hover:cursor-pointer font-bold"><a href={actuality.link} target="_blank" rel="noreferrer">Détails &gt; &gt;</a></p>
                                }
                            </div>

                          
                        </div>
                    );
                })}
            </div>

            </div>
        );
    }
}

