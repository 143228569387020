// Desc: Profile page
// Path: src/Utils.js

import React from "react";

import WebService from '../Service.js';
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// CSS
import "../App.css";

import Utils from "../Utils.js";



class PageTitle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>
            {/* Font size to 24px, font weight to 500, line height to 1.1 */}
                <h1 className="font-medium leading-[1.1] m-0 text-3xl text-baseFont pb-4">
                    {this.props.title}
                </h1>
            </div>
        );
    }
} export {PageTitle};


class ExtraTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <table className="w-full">
                <thead className="m-2">
                    <tr className="text-left text-white [&>*]:bg-darkBlue [&>*]:m-1 [&>*]:p-2 [&>*]:border-r-2">
                        {this.props.headers.map((header) => {
                            return (
                                <th className="">
                                    {header}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                {/* Even children have bg-litegray*/}
                <tbody className="[&>*]:border-b-2 [&>*]:text-black [&>*]:border-liteGray [&>*]:bg-white even:[&>*]:bg-liteGray">
                    {this.props.children}
                </tbody>
            </table>
        );
    }
} export {ExtraTable};

class MoneyText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let amount = parseFloat(this.props.amount);
        return (
                <p 
                className={`${amount > 0 ? "text-goodColor" : amount < 0 ? "text-badColor" : "text-waitingColor"} font-bold text-right `}>
                {Utils.formatMoney(amount)}
                </p>
        );
    }
} export {MoneyText};

class BackgroundText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div 
            onClick={this.props.onClick}
            
            className= {`bg-${this.props.color}  text-white p-2 font-bold rounded inline-block ${this.props.style}`}>
                {this.props.children}
            </div>
        );
    }
} export {BackgroundText};


class ElementTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        /* rounded */
        return (
            <div className="bg-white shadow-md p-4 border-t-4 border-middleBlue rounded m-3">
                {this.props.children}
            </div>
        );
    }
} export {ElementTile};

class StatTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="bg-white shadow-md flex float-left">
                <div className={`bg-${this.props.color} p-5 block`}>
                    <img src={this.props.icon} alt="icon" className="w-16 h-16"/>
                </div>
                <div className="p-2">
                    <h1 className="
                        text-baseFont
                        text-2xl 
                        whitespace-nowrap 
                        overflow-hidden 
                        truncate 
                        uppercase
                        ">
                        {this.props.title}</h1>
                    <h1 className="text-2xl text-black font-bold truncate
                    ">{this.props.value}</h1>
                </div>
            </div>
        );
    }
} export {StatTile};
