// Desc: Profile page
// Path: src/Utils.js

import React from "react";

import WebService from '../Service.js';

import {PageTitle, StatTile, ElementTile}  from "./Utils.js";

import UserIcon from "../images/utilisateur.png";
import Balance from "../images/balance.png";
import euro from "../images/euro.png";

import Utils from "../Utils.js"


// CSS
import "../App.css";



export default
class OverAll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>
                <PageTitle title="OverAll"/>
                <div className="flex flex-wrap gap-4">
                    {/*
                    <StatTile title="Cotisants" value="100" icon={UserIcon} color="waitingColor"/>
                    <StatTile title="Trésorerie" value={Utils.formatMoney(1239.29)} icon={Balance} color="goodColor"/>
                    <StatTile title="Créances" value={Utils.formatMoney(231.29)} icon={euro} color="middleColor"/>
                    */}
                </div>

           

            </div>
        );
    }
}